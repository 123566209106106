import { notifyError, notifySuccess } from "~/utils/notifications.js";
import { storeToRefs } from "pinia";
import { linksApiService } from "~/services/linksApiService";

export const useLinksApi = () => {
  const data = ref(null);
  const error = ref(null);
  const loading = ref(false);

  const { pinOrUnpinLink, resetLinkClicks, deleteLinks } = linksApiService();

  // Common logic for API calls
  const handleApiCall = async (apiFunction, body, callback) => {
    loading.value = true;
    data.value = null;

    try {
      const response = await apiFunction(body);
      if (response?.status === 200) {
        if (callback) callback();
        notifySuccess(response.data.data?.message);
      } else {
        const message =
          response.data?.message || "An unknown error occurred.";
        notifyError({ title: message });
      }
    } catch (err) {
      error.value = err?.message;
      notifyError();
    } finally {
      loading.value = false;
    }
  };

  const pinOrUnpinLinkApi = async ({ smartlnkIds, callback }) => {
    const body = { smartlnk_ids: smartlnkIds };
    handleApiCall(pinOrUnpinLink, body, callback);
  };

  const resetLinkClicksApi = async ({ smartlnkIds, callback }) => {
    const body = { smartlnk_ids: smartlnkIds };
    handleApiCall(resetLinkClicks, body, callback);
  };

  const deleteLinksApi = ({ smartlnkIds, callback }) => {
    const body = { smartlnk_ids: smartlnkIds };
    handleApiCall(deleteLinks, body, callback);
  };

  return {
    data,
    error,
    loading,
    resetLinkClicksApi,
    deleteLinksApi,
    pinOrUnpinLinkApi,
  };
};
